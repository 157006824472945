import {FaqElement, useFAQ} from "~/service/jsonLd/FAQ/useFAQ";

export type PartnerPage = { name: WidgetPartners, header: string, faq: FaqElement[] }
export enum Widgets {
    CASH_COMPARER = 'CASH_COMPARER',
    MORTGAGE_COMPARER = 'MORTGAGE_COMPARER',
    LIGHT_COMPARER_WIDGET = 'LIGHT_COMPARER_WIDGET',
    COMPARER_FORM = 'COMPARER_FORM',
    FLAT_FOR_START_WIDGET = 'FLAT_FOR_START_WIDGET',
    FLAT_FOR_START_FORM_WIDGET = 'FLAT_FOR_START_FORM_WIDGET',
    REFINANCE_CALCULATOR = 'REFINANCE_CALCULATOR',
    BASE_INSTALLMENT_PICKER = 'BASE_INSTALLMENT_PICKER',
    FLAT_WITHOUT_CONTRIBUTION = 'FLAT_WITHOUT_CONTRIBUTION',
    MEETING_PLANNER_WIDGET = 'MEETING_PLANNER_WIDGET',
    SIMULATION_FORM_MORTGAGE = 'SIMULATION_FORM_MORTGAGE',
    MORTGAGE_COMPARER_FORM_WIDGET = 'MORTGAGE_COMPARER_FORM_WIDGET',
    CREDITWORTHINESS_OFFERS_WIDGET = 'CREDITWORTHINESS_OFFERS_WIDGET',
    MBW_WIDGET = 'MBW_WIDGET',
    REFINANCE_WIDGET = 'REFINANCE_WIDGET',
    SIMULATION_FORM_FLAT_FOR_START = 'SIMULATION_FORM_FLAT_FOR_START',
    BANK_OFFER_PAGE = 'BANK_OFFER_PAGE',
}
export enum WidgetPartners {
    NOIZZ = 'NOIZZ',
    FORBES = 'FORBES',
    ROYAL_AG = 'ROYAL_AG',
    BUSINESS_INSIDER = 'BUSINESS_INSIDER',
    SYMULACJE_KREDYTOWE = 'SYMULACJE_KREDYTOWE',
    JAK_FINANSOWAC = 'JAK_FINANSOWAC',
    MORIZON = 'MORIZON',
    WAWEL_SERVICE = 'WAWEL_SERVICE',
    ORANGE = 'ORANGE',
    RASP = 'RASP',
    RANKOMANT = 'RANKOMANT',
    WSRM = 'WSRM',
    MDM_ENERGY = 'MDM_ENERGY',
    KREDYTOWY_PORADNIK = 'KREDYTOWY_PORADNIK',
    KREDYTOWY_PORADNIK_LENDI = 'KREDYTOWY_PORADNIK_LENDI',
    MICHELIN = 'MICHELIN'
}

const { LendiRaspFAQ, LendiOrangeFAQ,LendiWSRMFAQ,} = useFAQ();

export const PartnerPageDict: PartialRecord<WidgetPartners, PartnerPage> = {
    [WidgetPartners.ORANGE]: {
        name: WidgetPartners.ORANGE,
        header: 'Słyszałeś o rządowych programach mieszkaniowych, ale nie wiesz czy się do nich kwalifikujesz? Czujesz, że inflacja pożera Twój domowy budżet? Widzisz, jak comiesięczne raty stają się coraz wyższe? To czas, aby sięgnąć po wsparcie specjalisty!',
        faq: LendiOrangeFAQ,
    },
    [WidgetPartners.RASP]: {
        name: WidgetPartners.RASP,
        header: 'Inflacja pożera Twój domowy budżet? Raty kredytu co miesiąc masz wyższe? Czas na ucieczkę do przodu!',
        faq: LendiRaspFAQ,
    },
    [WidgetPartners.WSRM]: {
        name: WidgetPartners.WSRM,
        header: 'Inflacja pożera Twój domowy budżet? Raty kredytu co miesiąc masz wyższe? Czas na ucieczkę do przodu! Nie czekaj, skontaktuj się z ekspertem Lendi i sprawdź, co możesz zrobić, aby zyskać większy komfort finansowy na co dzień.',
        faq: LendiWSRMFAQ,
    },
    [WidgetPartners.MICHELIN]: {
        name: WidgetPartners.MICHELIN,
        header: 'Słyszałeś o rządowych programach mieszkaniowych, ale nie wiesz czy się do nich kwalifikujesz? Czujesz, że inflacja pożera Twój domowy budżet? Widzisz, jak comiesięczne raty stają się coraz wyższe? To czas, aby sięgnąć po wsparcie specjalisty!',
        faq: LendiOrangeFAQ,
    },
};
